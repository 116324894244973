export const Button = {
  baseStyle: {
    appearance: "none",
    border: "1px solid",
    borderRadius: "none",
    fontWeight: 400,
    letterSpacing: "normal",
    lineHeight: 1,
    maxW: "full",
    h: "auto",
    p: 0,
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      pointerEvents: "none",
      opacity: 1,
    },
  },
  sizes: {
    sm: {
      pt: 4.5,
      pb: 4.5,
      px: 2.5,
      fontSize: "sm",
      borderRadius: "xxs",
    },
    md: {
      pt: 6.25,
      pb: 5.75,
      px: 8,
      fontSize: "sm",
      borderRadius: "2xl",
      letterSpacing: "wide",
      textTransform: "uppercase",
      w: ["full", "auto"],
    },
    ghost: {
      mb: -1,
      fontSize: "xs",
      fontWeight: 400,
      letterSpacing: "generous",
      textTransform: "none",
    },
    text: {
      mb: -1,
      fontSize: "sm",
      fontWeight: 700,
      letterSpacing: "wider",
      textTransform: "uppercase",
    },
    underline: {
      mb: -1,
      fontSize: "xs",
      fontWeight: 400,
      letterSpacing: "wider",
      lineHeight: 2,
      textTransform: "uppercase",
    },
    lg: {
      mb: 1,
      fontSize: ["xxs", "xs", "sm", "md"],
      fontWeight: 400,
      letterSpacing: "wider",
      textTransform: "uppercase",
      borderRadius: "full",
      w: ["48", "60", "72"],
      h: ["8", "12"],
    },
  },
  variants: {
    primary: {
      bg: "brand.sunshine",
      borderColor: "brand.sunshine",
      color: "grey.cloud",
      fontWeight: 900,
      _hover: {
        bg: "brand.sunshine",
        borderColor: "brand.sunshine",
        color: "white",
        _disabled: {
          bg: "grey.fog",
          borderColor: "grey.fog",
          color: "grey.cloud",
        },
      },
      _active: {
        bg: "brand.sunshine",
        borderColor: "brand.sunshine",
        color: "grey.cloud",
      },
      _disabled: {
        bg: "grey.fog",
        borderColor: "grey.fog",
        color: "grey.cloud",
      },
    },
    primaryInverted: {
      bg: "white",
      borderColor: "white",
      color: "grey.darker",
      _hover: {
        bg: "grey.cloud",
        borderColor: "grey.cloud",
        color: "grey.darker",
        _disabled: {
          bg: "grey.fog",
          borderColor: "grey.fog",
          color: "grey.cloud",
        },
      },
      _active: {
        bg: "grey.cloud",
        borderColor: "grey.cloud",
        color: "grey.coal",
      },
      _disabled: {
        bg: "grey.fog",
        borderColor: "grey.fog",
        color: "grey.cloud",
      },
    },
    secondary: {
      bg: "grey.cloud",
      borderColor: "grey.cloud",
      color: "grey.darker",
      _hover: {
        bg: "white",
        borderColor: "grey.darker",
        color: "grey.darker",
        _disabled: {
          bg: "grey.fog",
          borderColor: "grey.fog",
          color: "grey.cloud",
        },
      },
      _active: {
        bg: "grey.cloud",
        borderColor: "grey.cloud",
        color: "grey.darker",
      },
      _disabled: {
        bg: "grey.fog",
        borderColor: "grey.fog",
        color: "grey.cloud",
      },
    },
    outline: {
      bg: "transparent",
      borderColor: "grey.cloud",
      color: "grey.darker",
      fontWeight: 900,
      _hover: {
        bg: "transparent",
        borderColor: "grey.darker",
        color: "grey.darker",
        _disabled: {
          bg: "transparent",
          borderColor: "grey.fog",
          color: "grey.fog",
        },
      },
      _active: {
        bg: "transparent",
        borderColor: "grey.cloud",
        color: "grey.darker",
      },
      _disabled: {
        bg: "transparent",
        borderColor: "grey.fog",
        color: "grey.fog",
      },
    },
    ghost: {
      bg: "transparent",
      border: 0,
      color: "grey.darker",
      _hover: {
        bg: "transparent",
        color: "grey.coal",
        _disabled: {
          bg: "transparent",
          color: "grey.mid",
        },
      },
      _active: {
        bg: "transparent",
        color: "grey.darker",
      },
      _disabled: {
        bg: "transparent",
        color: "grey.mid",
      },
    },
    ghostInverted: {
      bg: "transparent",
      border: 0,
      color: "white",
      _hover: {
        bg: "transparent",
        color: "grey.fog",
        _disabled: {
          bg: "transparent",
          color: "grey.fog",
        },
      },
      _active: {
        bg: "transparent",
        color: "white",
      },
      _disabled: {
        bg: "transparent",
        color: "grey.fog",
      },
    },
    underline: {
      bg: "transparent",
      border: 0,
      borderBottom: "1px solid",
      borderBottomColor: "brand.sunshine",
      color: "grey.darker",
      _hover: {
        bg: "transparent",
        color: "grey.darker",
        borderBottomColor: "transparent",
        _disabled: {
          bg: "transparent",
          color: "grey.mid",
        },
      },
      _active: {
        bg: "transparent",
        color: "grey.darker",
        borderBottomColor: "transparent",
      },
      _focus: {
        bg: "transparent",
        color: "grey.darker",
        borderBottomColor: "transparent",
      },
      _disabled: {
        bg: "transparent",
        color: "grey.mid",
      },
    },
    underlineTabs: {
      bg: "transparent",
      border: 0,
      borderBottom: "1px solid",
      borderBottomColor: "transparent",
      color: "grey.darker",
      _hover: {
        borderBottomColor: "transparent !important",
        bg: "transparent",
        color: "grey.darker",
        _disabled: {
          bg: "transparent",
          color: "grey.mid",
        },
      },
      _active: {
        bg: "transparent",
        color: "grey.darker",
        borderBottomColor: "brand.sunshine",
      },
      // _focus: {
      //   bg: "transparent",
      //   color: "grey.darker",
      //   borderBottomColor: "brand.sunshine",
      // },
      _disabled: {
        bg: "transparent",
        color: "grey.mid",
      },
    },
    underlineInverted: {
      bg: "transparent",
      border: 0,
      borderBottom: "1px solid",
      borderBottomColor: "white",
      color: "white",
      _after: {
        content: `" "`,
        d: "block",
        borderBottom: "2px solid",
        borderBottomColor: "white",
        transition: "width 0.2s ease",
        w: 0,
      },
      _hover: {
        bg: "transparent",
        color: "white",
        borderBottomColor: "transparent",
        _disabled: {
          bg: "transparent",
          color: "grey.fog",
        },
        _after: {
          w: "full",
        },
      },
      _active: {
        bg: "transparent",
        color: "white",
      },
      _disabled: {
        bg: "transparent",
        color: "grey.fog",
      },
    },
  },
  defaultProps: {
    size: "unset",
    variant: "primary",
  },
}
