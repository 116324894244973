import React, { memo, useMemo } from "react"
import { StackProps, Text, Stack, StackDirection, Flex } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useShopifyPrice } from "@app/hooks/useShopify"

import type { ProductProps, ProductVariantProps } from "@root/types/global"
import { graphql, useStaticQuery } from "gatsby"
import { useGlobalContext } from "@app/providers/global"

type Props = StackProps & {
  product?: ProductProps
  size?: string
  color?: string
  variant?: ProductVariantProps
  priceVaries?: boolean
  direction?: StackDirection
  switchSalePrice?: boolean
  dividerSpacing?: number | number[]
  quantity?: number
  isGwp?: boolean
  showBasePriceDivider?: boolean
}

export const ProductFormPrice: React.FC<Props> = memo(
  ({
    priceVaries = false,
    product,
    size = "xs",
    color,
    variant,
    switchSalePrice,
    dividerSpacing,
    quantity = 1,
    isGwp = false,
    showBasePriceDivider = true,
    ...props
  }) => {
    const { additionalFreeGift } = useGlobalContext()
    const { product: locales } = useStaticQuery<GatsbyTypes.ProductFormPriceQuery>(graphql`
      query ProductFormPrice {
        product: sanityTemplateProduct {
          additionalPriceVaries
        }
      }
    `)

    const { activeVariant } = useAppContext()

    const fallbackVariant = useMemo(() => product?.variants?.[0] || null, [product?.variants])

    const { formattedPrice, formattedCompareAuantityPrice, formattedCompareAtPrice, onSale } = useShopifyPrice(
      variant || activeVariant || fallbackVariant,
      false,
      quantity,
      false
    )

    const Divider: React.FC<{ color?: string }> = ({ color }) => (
      <Text as="span" mr={dividerSpacing || 4} size={size} color={color}>
        {"— "}
      </Text>
    )

    return (
      <>
        <Stack {...props}>
          {isGwp ? (
            <Text color="grey.darker" size={size}>
              {isGwp && additionalFreeGift}
            </Text>
          ) : onSale ? (
            <>
              <Text as="s" color={color || "grey.mid"} size={size} display="flex">
                {!switchSalePrice && <Divider color="grey.mid" />}
                {quantity != 1 ? formattedCompareAuantityPrice : formattedCompareAtPrice}
              </Text>
              <Text color="brand.sale" size={size}>
                {switchSalePrice && <Divider color="brand.sale" />}
                {formattedPrice}
              </Text>
            </>
          ) : (
            <Flex>
              {showBasePriceDivider && <Divider />}
              {priceVaries && <Text size={size}>{`${locales?.additionalPriceVaries} `}</Text>}
              <Text size={size} color={color}>
                {formattedPrice}
              </Text>
            </Flex>
          )}
        </Stack>
      </>
    )
  }
)

export default ProductFormPrice
