import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useMedia } from "@app/hooks/useMedia"
import { useNavigationHeader } from "@app/hooks/useNavigationHeader"
import { Icon } from "@app/components/Icon"

import { NavigationHeaderMobileBody } from "@app/components/Header/Navigation/HeaderNavigationMobileBody"

export type IndexObject = {
  index: { index: number; title: string }
  subIndex: { index: number; title: string }
  setSubIndex: React.Dispatch<React.SetStateAction<{ index: number; title: string }>>
  setIndex: React.Dispatch<React.SetStateAction<{ index: number; title: string }>>
  level: number
  setLevel: React.Dispatch<React.SetStateAction<number>>
  onClose: () => void
}

export const HeaderNavigationMobile: React.FC = memo(() => {
  const { state, dispatch } = useAppContext()
  const { isMedium } = useMedia()
  const { mobile } = useNavigationHeader()

  const [index, setIndex] = useState({ index: -1, title: "" })
  const [subIndex, setSubIndex] = useState({ index: -1, title: "" })
  const [level, setLevel] = useState(0)

  const drawerRef = useRef(null)

  const onClose = useCallback(() => {
    setIndex({ index: -1, title: "" })
    setSubIndex({ index: -1, title: "" })
    setLevel(0)
    dispatch({
      type: "menu",
      payload: false,
    })
  }, [dispatch])

  useEffect(() => {
    if (state.activeMenu === false || isMedium) {
      setIndex({ index: -1, title: "" })
      setSubIndex({ index: -1, title: "" })
      setLevel(0)
    }
  }, [state.activeMenu, isMedium])

  const mobileFiltered = {
    primary: mobile?.primary?.filter(item => item?._type === "objectPrimaryMobile"),
    secondary: mobile?.secondary?.filter(item => item?._type === "objectSecondaryMobile"),
  }

  return (
    <Drawer isOpen={state.activeMenu && !isMedium} onClose={onClose} placement="left" returnFocusOnClose={false} size="navigation">
      <DrawerOverlay />
      <DrawerContent>
        <Flex flexDirection="column" height="100dvh" color="neutral.darkGrey">
          <DrawerHeader>
            <Flex alignItems="center" borderBottom="1px solid" borderBottomColor="grey.cloud" paddingY="2">
              {level !== 0 && (
                <Button
                  display="flex"
                  onClick={() => {
                    if (level === 1) {
                      setIndex({ index: -1, title: "" })
                      setLevel(0)
                    } else if (level === 2) {
                      setSubIndex({ index: -1, title: "" })
                      setLevel(1)
                    }
                  }}
                  fontSize="12"
                  textTransform="uppercase"
                  height="12"
                  variant="ghost"
                >
                  <Icon name="chevron-nav-back" width={12} fill="transparent" height={11} />
                  <Text as={Heading} size="sm" fontWeight={500} lineHeight={{ base: "14px", md: "20px" }} color="grey.navigationText">
                    {level === 1 ? index.title : level === 2 ? subIndex.title : ""}
                  </Text>
                </Button>
              )}

              <DrawerCloseButton
                position="static"
                marginLeft="auto"
                onClick={onClose}
                width="12"
                height="12"
                variant="ghost"
                rounded="0"
                marginRight="-4"
              >
                <Icon name="closeAlt" w="12" />
              </DrawerCloseButton>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <NavigationHeaderMobileBody
              ref={drawerRef}
              items={mobileFiltered.primary}
              footerItems={mobileFiltered?.secondary}
              indexes={{ index, subIndex, setIndex, setSubIndex, setLevel, level, onClose }}
            />
          </DrawerBody>
        </Flex>
      </DrawerContent>
    </Drawer>
  )
})
