import { useCallback, useEffect, useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useSanityColour, SanityColourType } from "@app/hooks/useSanity"
import { useRoutes, Link } from "@app/hooks/useRoutes"

type Announcement = GatsbyTypes.SanityAnnouncement & {
  link: Link
}

type UseAnnouncement = {
  handlePause: (event: React.BaseSyntheticEvent) => void
  handlePlay: (event: React.BaseSyntheticEvent) => void
  item: Announcement | null
  size?: string
  theme: SanityColourType
}

export const useAnnouncement = (): UseAnnouncement => {
  const [active, setActive] = useState(0)
  const [playing, setPlaying] = useState(true)
  const { urlResolver } = useRoutes()

  const { data } = useStaticQuery<GatsbyTypes.StaticAnnouncementsQuery>(graphql`
    query StaticAnnouncements {
      data: sanitySettingAnnouncement {
        duration
        themeColour {
          themeColourText {
            theme
            custom {
              hex
            }
          }
          themeColourBackground {
            theme
            custom {
              hex
            }
          }
        }
        themeTextSize
        announcements {
          key: _key
          title
          link: _rawLink(resolveReferences: { maxDepth: 2 })
        }
      }
    }
  `)

  const items: Array<Announcement> = useMemo(
    () => data?.announcements?.map(item => ({ ...item, link: urlResolver(item?.link) })) || [],
    [data?.announcements, urlResolver]
  )

  const item = useMemo(() => items[active], [active, items])

  const size = data?.themeTextSize

  const theme = useSanityColour(data?.themeColour, { background: "white", colour: "grey.darker" })

  const handlePause = useCallback(() => setPlaying(false), [setPlaying])

  const handlePlay = useCallback(() => setPlaying(true), [setPlaying])

  useEffect(() => {
    if (data?.duration && items?.length > 1 && playing) {
      const interval = setInterval(() => {
        setActive(prevState => (prevState === items?.length - 1 ? 0 : prevState + 1))
      }, data.duration * 1000)

      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing])

  return {
    handlePause,
    handlePlay,
    item,
    size,
    theme,
  }
}
