export const space = {
  0.75: "0.1875rem",
  1.125: "0.28125rem",
  1.25: "0.3125rem",
  1.75: "0.4375rem",
  2.25: "0.5625rem",
  2.375: "0.5938rem",
  2.75: "0.6875rem",
  3.25: "0.8125rem",
  3.7: "0.938rem",
  3.75: "0.9375rem",
  4.25: "1.0625rem",
  4.5: "1.125rem",
  4.75: "1.1875rem",
  5.25: "1.3125rem",
  5.5: "1.375rem",
  5.75: "1.4375rem",
  6.25: "1.5625rem",
  6.5: "1.625rem",
  6.75: "1.6875rem",
  7.5: "1.875rem",
  8.25: "2.0625rem",
  8.5: "2.125rem",
  8.75: "2.1875rem",
  9.5: "2.375rem",
  10.5: "2.625rem",
  11: "2.75rem",
  11.25: "2.8125rem",
  11.5: "2.875rem",
  12: "3rem",
  12.5: "3.125rem",
  12.75: "3.1875rem",
  13: "3.25rem",
  13.75: "3.4375rem",
  15: "3.75rem",
  15.5: "3.875rem",
  16.25: "4.0625rem",
  16.5: "4.125rem",
  16.75: "4.1875rem",
  17: "4.25rem",
  17.5: "4.375rem",
  17.75: "4.4375rem",
  18: "4.5rem",
  18.75: "4.6875rem",
  19: "4.75rem",
  19.5: "4.875rem",
  21: "5.25rem",
  21.25: "5.3125rem",
  21.5: "5.375rem",
  22: "5.5rem",
  22.5: "5.625rem",
  22.75: "5.6875rem",
  23: "5.75rem",
  23.5: "5.875rem",
  24.25: "6.0625rem",
  24.5: "6.125rem",
  25: "6.25rem",
  25.25: "6.3125rem",
  27: "6.75rem",
  27.5: "6.875rem",
  29: "7.25rem",
  30: "7.5rem",
  31: "7.75rem",
  31.25: "7.8125rem",
  31.75: "7.9375rem",
  35.5: "8.875rem",
  35.75: "8.9375rem",
  37.5: "9.375rem",
  38: "9.5rem",
  41: "10.25rem",
  43.75: "10.9375rem",
  45.75: "11.4375rem",
  47.75: "11.9375rem",
  50: "12.5rem",
  54.5: "13.625rem",
  62.5: "15.625rem",
  71.25: "17.8125rem",
  79: "19.75rem",
  92: "23rem",
}
