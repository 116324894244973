export const Link = {
  baseStyle: {
    _hover: {
      textDecoration: "none",
    },
    _focus: {
      boxShadow: "none",
    },
  },
  sizes: {
    underline: {
      mb: -1,
      fontSize: "xs",
      fontWeight: 400,
      letterSpacing: "wider",
      lineHeight: 2,
      textTransform: "uppercase",
    },
  },
  variants: {
    underline: {
      d: "inline-block",
      border: 0,
      borderBottom: "1px solid",
      borderBottomColor: "brand.sunshine",
      color: "grey.darker",
      _hover: {
        color: "grey.darker",
        borderBottomColor: "transparent",
      },
      _active: {
        color: "grey.darker",
        borderBottomColor: "transparent",
      },
      _focus: {
        borderBottomColor: "transparent",
      },
      _disabled: {
        color: "grey.mid",
      },
    },
    underlineInverted: {
      d: "inline-block",
      border: 0,
      borderBottom: "1px solid",
      borderBottomColor: "white",
      color: "white",
      _hover: {
        color: "white",
        borderBottomColor: "transparent",
      },
      _active: {
        color: "white",
      },
      _disabled: {
        color: "grey.fog",
      },
    },
  },
  defaultProps: {
    size: "unset",
    variant: "unset",
  },
}
