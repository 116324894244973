import React, { forwardRef } from "react"
import { Box, Button, Flex, Text } from "@chakra-ui/react"

import { useNavigationLinkNormaliser } from "@app/hooks/useNavigationHeader"
import { Icon } from "@app/components/Icon"
import { Link } from "@app/components/Link"
import { useConfigContext } from "@app/providers/config"
import { useGlobalContext } from "@app/providers/global"

import { IndexObject } from "@app/components/Header/Navigation/HeaderNavigationMobile"
import { NavigationHeaderMobileThirdMenu } from "@app/components/Header/Navigation/HeaderNavigationMobileThirdMenu"
import { NavigationHeaderMobileSubmenu } from "@app/components/Header/Navigation/HeaderNavigationMobileSubMenu"

type NavBody = {
  items: GatsbyTypes.SanityObjectPrimaryMobile["items"]
  indexes: IndexObject
  footerItems: GatsbyTypes.SanityObjectSecondaryMobile["items"]
}

export const NavigationHeaderMobileBody = forwardRef<HTMLElement, NavBody>(({ footerItems, items, indexes }, ref) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { normaliseLink } = useNavigationLinkNormaliser()
  const accordionSubIndex = indexes.index.index
  const { additionalAccount, additionalWishlist } = useGlobalContext()

  return (
    <Flex flexDirection="column" position="relative" overflowX="hidden" overflowY="auto" marginBottom="auto" height="100%">
      <Flex
        ref={ref}
        position="absolute"
        top="0"
        left="0"
        overflowY="auto"
        width="100%"
        height="100%"
        transform={accordionSubIndex === -1 ? "translateX(0%)" : "translateX(-100%)"}
        transition="transform 0.3s ease"
        flexDirection="column"
      >
        <Flex flexDirection="column" paddingX="6">
          {items?.map((item, index) => {
            const resolvedLink = normaliseLink(item?.link && item?.link.link?.[0] ? item?.link.link?.[0] : null)
            return item && item.items && item.items.length > 0 ? (
              <Button
                as={Button}
                key={index}
                onClick={() => {
                  indexes.setIndex({ index, title: item?.title || "" })
                  indexes.setLevel(1)
                }}
                justifyContent="flex-start"
                alignItems="center"
                paddingLeft="3"
                variant="ghost"
                borderBottom={index < items.length - 1 ? "1px solid" : ""}
                borderBottomColor="grey.cloud"
                _hover={{
                  color: "unset",
                  background: "unset",
                  borderBottomColor: "grey.cloud",
                }}
              >
                <Flex
                  justifyContent="flex-start"
                  height={index < items.length - 1 ? "55px" : "56px"}
                  flexWrap="wrap"
                  alignItems="center"
                  width="100%"
                >
                  <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.navigationText">
                    {item?.title}
                  </Text>
                </Flex>
                <Flex width="48px" height="48px" justifyContent="center" alignItems="center" marginLeft="auto">
                  <Icon name="chevron-nav" width="12" height="12" fill="transparent" />
                </Flex>
              </Button>
            ) : item && resolvedLink ? (
              <Button
                key={index}
                as={Link}
                to={resolvedLink?.url}
                onClick={() => {
                  indexes.onClose()
                }}
                justifyContent="flex-start"
                alignItems="center"
                paddingLeft="3"
                variant="ghost"
                borderBottom={index < items.length - 1 ? "1px solid" : ""}
                borderBottomColor="grey.cloud"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Flex
                  justifyContent="flex-start"
                  height={index < items.length - 1 ? "55px" : "56px"}
                  flexWrap="wrap"
                  alignItems="center"
                  width="100%"
                >
                  <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.navigationText">
                    {item?.title}
                  </Text>
                </Flex>
              </Button>
            ) : null
          })}
        </Flex>
        <Flex flexDirection="column" paddingX="6" height="100%" background="grey.cloud">
          <Button
            as={Link}
            to={routes.DASHBOARD}
            onClick={() => {
              indexes.onClose()
            }}
            justifyContent="flex-start"
            alignItems="center"
            paddingLeft="3"
            variant="ghost"
            borderBottom="1px solid"
            borderBottomColor="grey.fog"
            _hover={{
              textDecoration: "none",
            }}
          >
            <Flex flexDirection="column" justifyContent="center" height="55px" flexWrap="wrap" alignItems="flex-start">
              <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.navigationText">
                {additionalAccount}
              </Text>
            </Flex>
          </Button>
          <Button
            as={Link}
            to={routes.WISHLIST}
            onClick={() => {
              indexes.onClose()
            }}
            justifyContent="flex-start"
            alignItems="center"
            paddingLeft="3"
            variant="ghost"
            borderBottom={footerItems && footerItems?.length > 0 ? "1px solid" : ""}
            borderBottomColor="grey.fog"
            _hover={{
              textDecoration: "none",
            }}
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              height={footerItems && footerItems?.length > 0 ? "55px" : "56px"}
              flexWrap="wrap"
              alignItems="flex-start"
            >
              <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.navigationText">
                {additionalWishlist}
              </Text>
            </Flex>
          </Button>

          {footerItems?.map((item, index) => {
            const resolvedLink = normaliseLink(item?.link)
            const parentLength = items?.length || 0
            const offsetIndex = parentLength + index
            return item && item.items && item.items?.length > 0 ? (
              <Button
                as={Button}
                key={offsetIndex}
                onClick={() => {
                  indexes.setIndex({ index: offsetIndex, title: item?.title || "" })
                  indexes.setLevel(1)
                }}
                justifyContent="flex-start"
                alignItems="center"
                paddingLeft="3"
                variant="ghost"
                borderBottom={index < footerItems.length - 1 ? "1px solid" : ""}
                borderBottomColor="grey.fog"
                _hover={{
                  color: "unset",
                  background: "unset",
                  borderBottomColor: "grey.fog",
                }}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  height={index < footerItems.length - 1 ? "55px" : "56px"}
                  flexWrap="wrap"
                  alignItems="flex-start"
                >
                  <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.navigationText">
                    {item?.title}
                  </Text>
                </Flex>
                <Flex width="48px" justifyContent="flex-end" alignItems="center" marginLeft="auto">
                  <Icon name="chevron-nav" width="12" height="12" fill="transparent" />
                </Flex>
              </Button>
            ) : item && resolvedLink ? (
              <Button
                key={offsetIndex}
                as={Link}
                to={resolvedLink?.url}
                onClick={() => {
                  indexes.onClose()
                }}
                justifyContent="flex-start"
                alignItems="center"
                paddingLeft="3"
                borderBottom={index < footerItems.length - 1 ? "1px solid" : ""}
                borderBottomColor="grey.fog"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  height={index < footerItems.length - 1 ? "55px" : "56px"}
                  flexWrap="wrap"
                  alignItems="flex-start"
                >
                  <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.navigationText">
                    {item?.title}
                  </Text>
                </Flex>
              </Button>
            ) : null
          })}
        </Flex>
      </Flex>
      {items?.map((item, index) => {
        return item && item.items ? (
          <Box
            key={index}
            position="absolute"
            top="0"
            left="0"
            overflowY="auto"
            width="100%"
            height="100dvh"
            maxHeight="100%"
            transform={accordionSubIndex === index ? "translateX(0%)" : "translateX(100%)"}
            transition="transform 0.3s ease"
          >
            {items && items?.length > 0 && <NavigationHeaderMobileSubmenu item={item} indexes={indexes} />}
          </Box>
        ) : null
      })}
      {footerItems?.map((item, index) => {
        const parentLength = items?.length || 0
        const offsetIndex = parentLength + index

        return item ? (
          <Box
            key={offsetIndex}
            position="absolute"
            top="0"
            left="0"
            overflowY="auto"
            width="100%"
            height="100dvh"
            maxHeight="100%"
            transform={accordionSubIndex === offsetIndex ? "translateX(0%)" : "translateX(100%)"}
            transition="transform 0.3s ease"
          >
            {footerItems && footerItems?.length > 0 && <NavigationHeaderMobileThirdMenu item={item} indexes={indexes} />}
          </Box>
        ) : null
      })}
    </Flex>
  )
})
