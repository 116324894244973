export const Select = {
  baseStyle: {
    field: {
      cursor: "pointer",
      border: "1px solid",
      borderRadius: "none",
      boxShadow: "none",
      fontSize: "xs",
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
    icon: {
      insetEnd: 4,
      opacity: "1 !important",
      width: 4,
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: "grey.lake",
        borderRadius: "lg",
        lineHeight: 1,
        h: 12.5,
        px: 4,
        boxShadow: "none",
        bg: "transparent",
        color: "grey.darker",
        pr: 12,
        _hover: {
          borderColor: "grey.lake",
          boxShadow: "none",
        },
        _focus: {
          borderColor: "grey.lake",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "grey.fog",
          boxShadow: "none",
          bg: "grey.cloud",
          color: "grey.fog",
          opacity: 1,
        },
        _invalid: {
          borderColor: "grey.lake",
          borderWidth: 1,
          boxShadow: "none",
          color: "grey.darker",
        },
      },
    },
    ghost: {
      field: {
        borderColor: "transparent",
        bg: "transparent",
        color: "grey.darker",
        letterSpacing: "generous",
        _hover: {
          borderColor: "transparent",
          boxShadow: "none",
        },
        _focus: {
          borderColor: "transparent",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "transparent",
          boxShadow: "none",
          bg: "transparent",
          color: "grey.fog",
          cursor: "default",
          opacity: 1,
        },
        _invalid: {
          borderColor: "transparent",
          borderWidth: 1,
          boxShadow: "none",
          color: "grey.darker",
        },
      },
      icon: {
        color: "grey.darker",
        insetEnd: 0,
      },
    },
    ghostInverted: {
      field: {
        borderColor: "transparent",
        bg: "transparent",
        color: "white",
        letterSpacing: "generous",
        _hover: {
          borderColor: "transparent",
          boxShadow: "none",
        },
        _focus: {
          borderColor: "transparent",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "transparent",
          boxShadow: "none",
          bg: "transparent",
          color: "grey.fog",
          cursor: "default",
          opacity: 1,
        },
        _invalid: {
          borderColor: "transparent",
          borderWidth: 1,
          boxShadow: "none",
          color: "white",
        },
      },
      icon: {
        color: "white",
        insetEnd: 0,
      },
    },
  },
  defaultProps: {
    size: "base",
    variant: "outline",
  },
}
