import React, { memo } from "react"
import { AspectRatio, AspectRatioProps, Box, Flex, Image } from "@chakra-ui/react"
import { Link } from "@app/components/Link"
import { useNavigationLinkNormaliser } from "@app/hooks/useNavigationHeader"
import { useImage } from "@app/hooks/useImage"

type Props = {
  active: boolean
  handleClick: () => void
  item: GatsbyTypes.SanityObjectNavTemplate1 | GatsbyTypes.SanityObjectNavTemplate2 | GatsbyTypes.SanityObjectNavTemplate3
}

type Template1Props = {
  item: GatsbyTypes.SanityObjectNavTemplate1
  handleClick: () => void
}

type Template2Props = {
  item: GatsbyTypes.SanityObjectNavTemplate2
  handleClick: () => void
}

type Template3Props = {
  item: GatsbyTypes.SanityObjectNavTemplate3
  handleClick: () => void
}

type FeaturedCardProps = {
  width?: string
  card: GatsbyTypes.SanityNavigationCategory | null
  aspectRatioProps: AspectRatioProps
  handleClick: () => void
}

export const HeaderNavigationDesktopTemplates: React.FC<Props> = memo(({ active, handleClick, item }) => {
  const { primaryLink } = item
  const { normaliseLink } = useNavigationLinkNormaliser()
  const navigation = normaliseLink(primaryLink?.link?.[0] ? primaryLink?.link?.[0] : null)

  return (
    <>
      <Box pos="absolute" insetX={0} pt={4} opacity={active ? 1 : 0} visibility={active ? "visible" : "hidden"} w="full">
        <Box bg="grey.white" borderTop="1px solid" borderColor="grey.fog" minHeight="432px">
          {primaryLink && navigation && (
            <Flex
              background={
                primaryLink.colour?.themeColourBackground
                  ? primaryLink.colour?.themeColourBackground?.theme === "custom"
                    ? primaryLink.colour?.themeColourBackground?.custom?.hex
                    : primaryLink.colour?.themeColourBackground?.theme
                  : "grey.cloud"
              }
              alignItems="center"
              minHeight="56px"
            >
              <Box paddingX="14" paddingY="2" width="100%" maxW="container.xl" marginX="auto">
                <Link
                  to={navigation.url}
                  aria-label={primaryLink.title || navigation.label || ""}
                  color={primaryLink?.colour?.themeColourText?.theme ? primaryLink?.colour?.themeColourText?.theme : "grey.coal"}
                  paddingTop="3"
                  paddingBottom="2"
                  paddingRight="3"
                  textTransform="uppercase"
                  fontSize="sm"
                  fontWeight={450}
                  letterSpacing="wider"
                  _hover={{
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                    color: "grey.coal",
                  }}
                >
                  {primaryLink.title || navigation.label}
                </Link>
              </Box>
            </Flex>
          )}
          <Box height="100%">
            {item._type === "objectNavTemplate1" && <HeaderNavigationTemplate1 item={item} handleClick={handleClick} />}
            {item._type === "objectNavTemplate2" && <HeaderNavigationTemplate2 item={item} handleClick={handleClick} />}
            {item._type === "objectNavTemplate3" && <HeaderNavigationTemplate3 item={item} handleClick={handleClick} />}
          </Box>
        </Box>
      </Box>
    </>
  )
})

const HeaderFeaturedCard: React.FC<FeaturedCardProps> = ({ card, width = "100%", aspectRatioProps, handleClick }) => {
  const { getGatsbyImage } = useImage()
  const { normaliseLink } = useNavigationLinkNormaliser()

  if (!card) return null

  const { image, link } = card
  const resolvedImage = image ? getGatsbyImage(image) : null

  const normalisedLink = normaliseLink(link?.[0] ? link?.[0] : null)

  return resolvedImage && normalisedLink ? (
    <Link to={normalisedLink.url} width={width} aria-label={normalisedLink.label || ""} onClick={handleClick}>
      <AspectRatio {...aspectRatioProps}>
        <Image src={resolvedImage?.src} alt={card.title || ""} />
      </AspectRatio>
    </Link>
  ) : null
}

const HeaderNavigationTemplate1: React.FC<Template1Props> = ({ item, handleClick }) => {
  const { items } = item
  const { normaliseLink } = useNavigationLinkNormaliser()
  const [active, setActive] = React.useState<number | null>(null)

  return (
    <Flex justifyContent="space-between" maxW="container.xl" marginX="auto" width="100%">
      <Box paddingY="6" paddingX="14" width="27%">
        {items?.map((item, index) => {
          const normalisedLink = normaliseLink(item)
          return normalisedLink ? (
            <Link
              key={index}
              to={normalisedLink.url}
              display="flex"
              paddingTop="3"
              paddingRight="3"
              paddingBottom="2"
              textTransform="uppercase"
              fontSize="sm"
              letterSpacing="wider"
              fontWeight={450}
              onClick={handleClick}
              onMouseEnter={() => setActive(index)}
              onMouseLeave={() => setActive(null)}
              onTouchStart={() => setActive(index)}
              {...(active === null
                ? { color: "grey.coal" }
                : active === index
                ? {
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                    color: "grey.coal",
                  }
                : {
                    color: "grey.mid",
                  })}
            >
              {normalisedLink.label}
            </Link>
          ) : null
        })}
      </Box>
      <Flex width="73%" justifyContent="flex-end" columnGap="1">
        {item.featuredCards &&
          item.featuredCards.map((card: GatsbyTypes.SanityNavigationCategory | null, index: number) => (
            <HeaderFeaturedCard
              key={index}
              card={card}
              handleClick={handleClick}
              aspectRatioProps={{ ratio: { md: 0.93, xl: 0.875 } }}
              width="calc(100% / 3)"
            />
          ))}
      </Flex>
    </Flex>
  )
}

const HeaderNavigationTemplate2: React.FC<Template2Props> = ({ item, handleClick }) => {
  const { items } = item
  const { normaliseLink } = useNavigationLinkNormaliser()
  const [active, setActive] = React.useState<Array<number> | null>(null)

  return (
    <Flex justifyContent="space-between" maxW="container.xl" marginX="auto">
      <Flex paddingY="6" paddingX="14" width="51%">
        {items?.map((column, index) =>
          column?.link && column?.link.length > 0 ? (
            <Box key={index} width={index === 0 ? "max(208px, calc(100% / 3))" : "unset"}>
              {column.link.map((columnLink, secondaryIndex) => {
                const normalisedLink = normaliseLink(columnLink)
                return columnLink && normalisedLink ? (
                  <Link
                    key={secondaryIndex}
                    to={normalisedLink.url}
                    display="flex"
                    paddingTop="3"
                    paddingRight="3"
                    paddingBottom="2"
                    textTransform="uppercase"
                    fontSize="sm"
                    fontWeight={450}
                    letterSpacing="wider"
                    onClick={handleClick}
                    onMouseEnter={() => setActive([index, secondaryIndex])}
                    onMouseLeave={() => setActive(null)}
                    onTouchStart={() => setActive([index, secondaryIndex])}
                    {...(active === null
                      ? { color: "grey.coal" }
                      : active[0] === index && active[1] === secondaryIndex
                      ? {
                          textDecoration: "underline",
                          textUnderlineOffset: "3px",
                          color: "grey.coal",
                        }
                      : {
                          color: "grey.mid",
                        })}
                  >
                    {normalisedLink.label}
                  </Link>
                ) : null
              })}
            </Box>
          ) : null
        )}
      </Flex>
      {item.featuredCard && (
        <Flex width="49%">
          <HeaderFeaturedCard card={item.featuredCard} handleClick={handleClick} aspectRatio={{ ratio: 1.876 }} />
        </Flex>
      )}
    </Flex>
  )
}

const HeaderNavigationTemplate3: React.FC<Template3Props> = ({ item, handleClick }) => {
  const { items } = item
  const { normaliseLink } = useNavigationLinkNormaliser()
  const [active, setActive] = React.useState<Array<number> | null>(null)

  return (
    <Flex justifyContent="space-between" maxW="container.xl" marginX="auto">
      <Flex paddingY="6" paddingX="14" columnGap="4" width="100%">
        {items?.map((column, index) => {
          return column?.link && column?.link.length > 0 ? (
            <Box key={index} width={`calc(1/${items.length})`} minWidth={{ md: "unset", lg: "208px" }}>
              {column.link.map(columnLink => {
                const normalisedLink = normaliseLink(columnLink)
                return columnLink && normalisedLink ? (
                  <Link
                    key={0}
                    to={normalisedLink.url}
                    display="flex"
                    paddingTop="3"
                    paddingRight="3"
                    paddingBottom="2"
                    textTransform="uppercase"
                    fontSize="sm"
                    fontWeight={450}
                    letterSpacing="wider"
                    onClick={handleClick}
                    onMouseEnter={() => setActive([index, 0])}
                    onMouseLeave={() => setActive(null)}
                    onTouchStart={() => setActive([index, 0])}
                    {...(active === null
                      ? { color: "grey.coal" }
                      : active[0] === index && active[1] === 0
                      ? {
                          textDecoration: "underline",
                          textUnderlineOffset: "3px",
                          color: "grey.coal",
                        }
                      : {
                          color: "grey.mid",
                        })}
                  >
                    {column.title}
                  </Link>
                ) : null
              })}
              {column.items?.map((columnChild, secondaryIndex) => {
                const normalisedLink = normaliseLink(columnChild)
                return columnChild && normalisedLink ? (
                  <Link
                    key={secondaryIndex}
                    to={normalisedLink.url}
                    display="flex"
                    paddingTop="3"
                    paddingRight="3"
                    paddingBottom="2"
                    fontWeight={450}
                    fontSize="sm"
                    onClick={handleClick}
                    onMouseEnter={() => setActive([index, secondaryIndex + 1])}
                    onMouseLeave={() => setActive(null)}
                    onTouchStart={() => setActive([index, secondaryIndex + 1])}
                    textTransform="capitalize"
                    {...(active === null
                      ? { color: "grey.coal" }
                      : active[0] === index && active[1] === secondaryIndex + 1
                      ? {
                          textDecoration: "underline",
                          textUnderlineOffset: "3px",
                          color: "grey.coal",
                        }
                      : {
                          color: "grey.mid",
                        })}
                  >
                    {normalisedLink.label}
                  </Link>
                ) : null
              })}
            </Box>
          ) : null
        })}
      </Flex>
    </Flex>
  )
}
