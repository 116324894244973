import React, { memo, useCallback } from "react"
import { Button, Text } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCustomerContext } from "@app/providers/customer"
import { useConfigContext } from "@app/providers/config"
import { Icon } from "@app/components/Icon"
import { Link } from "@app/components/Link"
import { useGlobalContext } from "@app/providers/global"

type Props = {
  hover?: boolean
  setActive?: (value: string | null) => void
  activeDesktop?: string | null
}

export const AccountWidget: React.FC<Props> = memo(({ hover = false, setActive, activeDesktop }) => {
  const { additionalAccount } = useGlobalContext()
  const { state, dispatch } = useAppContext()
  const {
    settings: { routes },
  } = useConfigContext()

  const { customer } = useCustomerContext()

  const handleClick = useCallback(() => {
    if (setActive) {
      setActive(null)
    }
    dispatch({
      type: "menu",
      payload: false,
    })
  }, [dispatch, setActive])

  return (
    <>
      <Button
        as={Link}
        display={{ base: "none", md: "block" }}
        title={additionalAccount || ""}
        aria-label={additionalAccount || ""}
        to={customer ? routes.DASHBOARD : routes.LOGIN}
        onClick={handleClick}
        variant={
          state.activeInversion && !state.activeMenu && !state.activeNavigation && !hover && !activeDesktop ? "ghostInverted" : "ghost"
        }
        _hover={{
          textDecoration: "underline",
          textUnderlineOffset: "3px",
        }}
      >
        <Text textTransform="uppercase" letterSpacing="wider" lineHeight={1} ml={2.5} mb={-1} size="sm">
          {additionalAccount}
        </Text>
      </Button>

      <Button
        as={Link}
        display={{ base: "flex", md: "none" }}
        alignItems="center"
        justifyContent="space-between"
        onClick={handleClick}
        py={4}
        size="text"
        title={additionalAccount}
        aria-label={additionalAccount}
        to={customer ? routes.DASHBOARD : routes.LOGIN}
        transition="all 0.2s ease"
        variant="ghost"
        w="full"
      >
        <span>{additionalAccount}</span>
        <Icon name="account" width={3.75} />
      </Button>
    </>
  )
})
