import React, { memo, useCallback } from "react"
import { Box, Button, Text } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import { useWishlist } from "@app/hooks/useWishlist"
import { Icon } from "@app/components/Icon"
import { Link } from "@app/components/Link"
import { useGlobalContext } from "@app/providers/global"

type Props = {
  hover?: boolean
  setActive?: (value: string | null) => void
  activeDesktop?: string | null
}

export const WishlistWidget: React.FC<Props> = memo(({ hover = false, setActive, activeDesktop }) => {
  const { additionalWishlist } = useGlobalContext()
  const { state, dispatch } = useAppContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const { count } = useWishlist()

  const handleClick = useCallback(() => {
    if (setActive) {
      setActive(null)
    }

    dispatch({
      type: "menu",
      payload: false,
    })
  }, [dispatch, setActive])

  return (
    <>
      <Box
        display={{ base: "none", md: "block" }}
        pos="relative"
        _hover={{
          textDecoration: "underline",
          textUnderlineOffset: "3px",
        }}
      >
        <Box opacity={count > 0 ? 0 : 1} transition="opacity 0.2s ease">
          <Button
            as={Link}
            display="block"
            title={`${additionalWishlist} (${count})`}
            aria-label={`${additionalWishlist} (${count})`}
            to={routes.WISHLIST}
            variant={
              state.activeInversion && !state.activeMenu && !state.activeNavigation && !hover && !activeDesktop ? "ghostInverted" : "ghost"
            }
            onClick={handleClick}
          >
            <Text textTransform="uppercase" letterSpacing="wider" lineHeight={1} mb={-1} size="sm">
              {additionalWishlist}
            </Text>
          </Button>
        </Box>
        <Box inset={0} opacity={count > 0 ? 1 : 0} pos="absolute" transition="opacity 0.2s ease">
          <Button
            as={Link}
            display="block"
            title={`${additionalWishlist} (${count})`}
            aria-label={`${additionalWishlist} (${count})`}
            to={routes.WISHLIST}
            variant={state.activeInversion && !state.activeMenu && !state.activeNavigation && !hover ? "ghostInverted" : "ghost"}
            onClick={handleClick}
            _hover={{
              textDecoration: "underline",
              textUnderlineOffset: "3px",
            }}
          >
            <Text textTransform="uppercase" letterSpacing="wider" lineHeight={1} mb={-1} size="sm">
              {additionalWishlist}
            </Text>
          </Button>
        </Box>
      </Box>

      <Button
        as={Link}
        display={{ base: "flex", md: "none" }}
        alignItems="center"
        justifyContent="space-between"
        onClick={handleClick}
        py={4}
        size="text"
        title={additionalWishlist}
        aria-label={additionalWishlist}
        to={routes.WISHLIST}
        transition="all 0.2s ease"
        variant="ghost"
        w="full"
      >
        <span>{additionalWishlist}</span>
        <Box pos="relative">
          <Box opacity={count > 0 ? 0 : 1} transition="opacity 0.2s ease">
            <Icon name="heart" width={3.75} />
          </Box>
          <Box inset={0} opacity={count > 0 ? 1 : 0} pos="absolute" transition="opacity 0.2s ease">
            <Icon name="heart-filled" width={3.75} />
          </Box>
        </Box>
      </Button>
    </>
  )
})
