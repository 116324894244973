import React, { memo } from "react"
import { Box, Container, Flex, Text } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useAnnouncement } from "@app/hooks/useAnnouncement"
import { Link } from "@app/components/Link"

export const Announcement: React.FC = memo(() => {
  const { announcementRef } = useAppContext()
  const { item, handlePlay, handlePause, size, theme } = useAnnouncement()

  return item ? (
    <Box
      as="section"
      ref={announcementRef}
      bg={theme?.background}
      color={theme?.colour}
      onMouseEnter={handlePause}
      onMouseLeave={handlePlay}
      pos="relative"
      zIndex={10}
    >
      <Container>
        <Flex alignItems="center" justifyContent="center" textAlign="center">
          {item?.link?.url ? (
            <Text
              as={Link}
              display="block"
              letterSpacing="generous"
              py={2}
              size={size || "xxs"}
              title={item?.link?.title || item?.title}
              aria-label={item?.link?.title || item?.title}
              to={item?.link?.url}
              w="full"
            >
              {item?.title}
            </Text>
          ) : (
            <Text display="block" letterSpacing="generous" py={2} size={size || "xxs"} w="full">
              {item?.title}
            </Text>
          )}
        </Flex>
      </Container>
    </Box>
  ) : null
})
