import { useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useImage } from "@app/hooks/useImage"
import { useRoutes } from "@app/hooks/useRoutes"
import { useShopify } from "@app/hooks/useShopify"

import type { ProductProps, ProductCombinedOrSetProps, ProductVariantProps } from "@root/types/global"
import type { ElasticProduct } from "@usereactify/search"

type SwatchItem = GatsbyTypes.SanitySettingSwatches & {
  colour: string
  image: any
}

export type Swatch = {
  key: string
  handle: string
  images: Array<any>
  image: string | null
  name: string
  swatch: SwatchItem
  url: string
}

export type SwatchFull = {
  key: string
  handle: string
  type: string
  product: ProductProps
  variants: Array<ProductVariantProps>
  images: Array<any>
  image: string | null
  name: string
  swatch: SwatchItem
  url: string
}

export type GroupSize = {
  key: string
  variant: ProductVariantProps
}

export type UseSwatch = {
  getColour: (name?: string) => SwatchItem | null
  getSwatches: (product: ElasticProduct | ProductCombinedOrSetProps | ProductProps) => Promise<Array<Swatch> | Array<SwatchFull>>
  getProductFormSwatches: (products: Array<ProductProps>, variant?: ProductVariantProps | null) => Array<Swatch>
  getGroupSizes: (product: ProductProps | ProductCombinedOrSetProps | ElasticProduct) => Promise<Array<GroupSize>>
  formatActiveProduct: (
    product: ElasticProduct | ProductProps,
    variant?: ProductVariantProps | null,
    fullData?: boolean,
    isReactifyData?: boolean
  ) => Swatch | SwatchFull
}

export const useSwatch = (): UseSwatch => {
  const { swatches: rawSwatches } = useStaticQuery<GatsbyTypes.StaticSwatchQuery>(graphql`
    query StaticSwatch {
      swatches: allSanitySettingSwatches {
        edges {
          node {
            handle {
              current
            }
            colour {
              hex
            }
            image: _rawImage(resolveReferences: { maxDepth: 2 })
          }
        }
      }
    }
  `)
  const {
    settings: {
      product: { colourPrefix, siblingPrefix, siblingSizePrefix, swatchPrefix, swatchURL, filterSortOrder },
      params,
      routes,
    },
  } = useConfigContext()

  const { currencyCode } = useCartContext()
  const {
    helpers: { capitalise, edgeNormaliser, handleize, decodeShopifyId },
  } = useCore()
  const { getGatsbyImage } = useImage()
  const { urlResolver } = useRoutes()
  const { imageNormaliser, adminProductNormaliser, getProductsByTag } = useShopify()

  const swatches: Array<GatsbyTypes.SanitySettingSwatches> = useMemo(() => edgeNormaliser(rawSwatches), [edgeNormaliser, rawSwatches])

  const getColour = useCallback(
    (name?: string) => {
      if (!name) return null

      const item = swatches?.find(({ handle }) => handleize(name) === handle?.current)
      const swatch = item
        ? {
            ...item,
            colour: item?.colour?.hex,
            image: getGatsbyImage(item?.image, { width: 48 }),
          }
        : null

      return swatch as SwatchItem
    },
    [getGatsbyImage, handleize, swatches]
  )

  const getGroupSizes = useCallback(
    async (product: ProductProps | ProductCombinedOrSetProps | ElasticProduct) => {
      const tag = product?.tags?.find(tag => tag?.includes(siblingSizePrefix))

      if (!tag) return []
      const siblingProducts = await getProductsByTag({ tag, firstImages: 0, firstVariants: 1 })

      return siblingProducts
        ?.filter(siblingProduct => !!siblingProduct?.variants?.[0])
        ?.map(siblingProduct => {
          return {
            key: siblingProduct?.handle,
            variant: siblingProduct.variants[0],
          }
        })
        ?.sort(
          ({ variant: variantsA }, { variant: variantsB }) =>
            filterSortOrder.indexOf(variantsA?.title) - filterSortOrder.indexOf(variantsB?.title)
        ) as unknown as Array<GroupSize>
    },
    [filterSortOrder, getProductsByTag, siblingSizePrefix]
  )

  const formatActiveProduct = useCallback(
    (siblingProduct: ElasticProduct | ProductProps, variant?: ProductVariantProps | null, fullData?: boolean, isReactifyData?: boolean) => {
      const selectedVariant =
        variant?.selectedOptions?.length && siblingProduct?.variants !== undefined && siblingProduct.variants?.length > 1
          ? siblingProduct?.variants?.find(
              ({ selectedOptions }) =>
                variant?.selectedOptions?.filter(({ name, value }) =>
                  selectedOptions?.find(selectedOption => name === selectedOption?.name && value === selectedOption?.value)
                )?.length === variant?.selectedOptions?.length
            )
          : null
      const selectedVariantId = selectedVariant ? decodeShopifyId(selectedVariant?.id, "ProductVariant") : null
      const image = siblingProduct?.tags?.find(tag => tag?.includes(swatchPrefix))?.split(":")?.[1]
      const images = siblingProduct?.images?.length ? siblingProduct.images.map(image => imageNormaliser(image, 1000)) : []
      const name = siblingProduct?.tags
        ?.find(tag => tag?.includes(colourPrefix))
        ?.split(":")?.[1]
        ?.replace(/-/g, " ")

      const url = `${urlResolver({ handle: siblingProduct?.handle }, routes.PRODUCT)?.url}${
        selectedVariantId ? `?${params.variant}=${selectedVariantId}` : ""
      }`

      const swatch = getColour(name)

      const variantData = siblingProduct?.variants?.map(variant => ({
        ...variant,
        id: variant?.id,
        quantityAvailable: variant?.inventoryQuantity ? variant.inventoryQuantity : 0,
        priceV2: !variant?.priceV2
          ? {
              amount: variant?.price,
              currencyCode: currencyCode,
            }
          : variant?.priceV2,
        compareAtPriceV2: !variant?.compareAtPriceV2
          ? {
              amount: variant?.compareAtPrice,
              currencyCode: currencyCode,
            }
          : variant?.compareAtPriceV2,
      }))

      if (fullData) {
        return {
          handle: siblingProduct?.handle,
          name: capitalise(name),
          type: siblingProduct?.productType,
          product: isReactifyData ? adminProductNormaliser(siblingProduct, { imageSize: 1000 }) : siblingProduct,
          variants: variantData,
          images,
          image: image ? `${swatchURL}${image}` : null,
          swatch,
          url,
        } as SwatchFull
      } else {
        return {
          handle: siblingProduct?.handle,
          key: (siblingProduct?.handle as string) || "",
          images,
          image: image ? `${swatchURL}${image}` : null,
          name: capitalise(name),
          swatch,
          url,
        } as Swatch
      }
    },
    [
      adminProductNormaliser,
      capitalise,
      currencyCode,
      colourPrefix,
      decodeShopifyId,
      getColour,
      imageNormaliser,
      params.variant,
      routes.PRODUCT,
      swatchPrefix,
      swatchURL,
      urlResolver,
    ]
  )

  const getProductFormSwatches = useCallback(
    (products: Array<ProductProps>, variant?: ProductVariantProps | null) => {
      return products
        ?.map(product => {
          return formatActiveProduct(product, variant, false, false)
        })
        ?.sort((a, b) => (a?.name > b?.name ? 1 : -1)) as Array<Swatch> | Array<SwatchFull>
    },
    [formatActiveProduct]
  )

  const getSwatches = useCallback(
    async (product: ProductProps | ProductCombinedOrSetProps | ElasticProduct) => {
      // Determine the "styleCode:" tag for current product
      const tag = product?.tags?.find(tag => tag?.includes(siblingPrefix))

      if (!tag) return []

      // Determine if the product data comes from Reactify by checking if the 'storefrontId' field exists
      const isReactifyData = "storefrontId" in product

      // Merge current product with related products to get full sibling products list.
      // If product data does not come from Reactify, do a live query to fetch sibling products by tag.
      const siblingProducts = isReactifyData
        ? [product, ...(product?.related || [])]
        : await getProductsByTag({ tag, firstImages: 100, firstVariants: 100 })

      return siblingProducts
        ?.map((siblingProduct: any) => {
          return formatActiveProduct(siblingProduct, null, true, isReactifyData)
        })
        ?.sort((a, b) => (a?.name > b?.name ? 1 : -1)) as Array<Swatch> | Array<SwatchFull>
    },
    [formatActiveProduct, getProductsByTag, siblingPrefix]
  )

  return { getColour, getSwatches, getGroupSizes, formatActiveProduct, getProductFormSwatches }
}
