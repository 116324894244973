import React, { useCallback } from "react"
import parse from "html-react-parser"
import { Element } from "domhandler/lib/node"

import { useCore } from "@app/hooks/useCore"
import { useRoutes, RouteOptions } from "@app/hooks/useRoutes"
import { Link } from "@app/components/Link"

type SanityColourButtonType = {
  ghost: string
  primary: string
  underline: string
}

export type SanityColourType = {
  background?: string
  button?: SanityColourButtonType
  colour?: string
}

type ThemeProps = {
  custom?: {
    hex?: string
  }
  theme: string
}

type SanityColourProps = {
  themeColourBackground: ThemeProps | undefined
  themeColourText: ThemeProps | undefined
}

type SanityContentOptions = {
  urlResolver?: RouteOptions
}

export const useSanityColour = (props: SanityColourProps, fallbacks?: any) => {
  const { themeColourBackground, themeColourText } = props || {}
  const fallback = {
    background: fallbacks?.background || (typeof fallbacks === "string" && fallbacks) || "",
    button: fallbacks?.button || (typeof fallbacks === "string" && fallbacks) || "",
    colour: fallbacks?.colour || (typeof fallbacks === "string" && fallbacks) || "",
  }

  const getThemedColour = (theme: string) => {
    const colour = theme?.toLowerCase()?.replace(/ /g, ".")

    return colour
  }

  const transformColour = (item: ThemeProps, fallback: string) => {
    if (!item?.theme || (item?.theme === "custom" && !item?.custom?.hex)) return fallback
    const value = item?.theme === "custom" && item?.custom?.hex ? item?.custom?.hex : getThemedColour(item?.theme)
    return value
  }

  const transformButton = (colour?: string) => {
    if (!colour) return { ghost: fallback?.button, primary: fallback?.button, underline: fallback?.button }

    const value =
      colour === "white" || colour === "#ffffff"
        ? { ghost: "ghostInverted", primary: "inverted", underline: "underlineInverted" }
        : { ghost: "ghost", primary: "solid", underline: "underline" }

    return value
  }

  const background = transformColour(themeColourBackground, fallback?.background)
  const colour = transformColour(themeColourText, fallback?.colour)
  const button = transformButton(colour)

  return { background, button, colour }
}

export const useSanityContent = (options: SanityContentOptions = {}) => {
  const {
    helpers: { sanityContent: content, sanitySerializers },
  } = useCore()
  const { urlResolver } = useRoutes()

  const renderCode = html => {
    const elementId = Math.random().toString(36).substring(9)

    return parse(html, {
      replace: domNode => {
        if (domNode && domNode instanceof Element && domNode.attribs) {
          if (domNode.type === `script`) {
            const script = document.createElement(`script`)
            if (domNode.attribs.src) script.src = domNode.attribs.src
            // @ts-ignore
            if (domNode.children.length) script.innerHTML = domNode.children[0].data
            script.id = `code-${elementId}`
            script.className = `code-${elementId}`
            script.type = `text/javascript`
            script.async = true
            script.defer = true
            document.body.prepend(script)
            return <React.Fragment key={elementId} />
          }
        }

        return domNode
      },
    })
  }

  const serializers = sanitySerializers({
    marks: {
      document: ({ children, mark }) => {
        const link = urlResolver(mark?.document?.document, "", options?.urlResolver)

        return (
          <Link to={link?.url} title={link?.title} variant="underline">
            {children}
          </Link>
        )
      },
      link: ({ children, mark }) => {
        const link = urlResolver(mark?.link, "", options?.urlResolver)

        return (
          <Link external={link?.external} title={link?.title} aria-label={link?.title} to={link?.url} variant="underline">
            {children}
          </Link>
        )
      },
    },
    types: {
      code: ({ node }) => renderCode(node.code),
    },
  })

  const sanityContent = useCallback(rawContent => content(rawContent, serializers), [content, serializers])

  return { sanityContent }
}
