export const Heading = {
  baseStyle: {
    fontFamily: "unset",
    fontWeight: 900,
    textTransform: "uppercase",
  },
  sizes: {
    h1: {
      fontSize: ["8xl", "7xl"],
      lineHeight: [1.11, 1.667],
      letterSpacing: "loosest",
    },
    h2: {
      fontSize: ["6xl", "7xl"],
      fontWeight: 700,
      lineHeight: [1.1, 1.667],
      letterSpacing: "looser",
    },
    h3: {
      fontSize: ["5xl", "2xl"],
      lineHeight: [1.125, 2],
      letterSpacing: "roomy",
    },
    h4: {
      fontSize: ["3xl", "xl"],
      lineHeight: [1.142, 1.4],
      letterSpacing: "loose",
    },
    h5: {
      fontSize: ["xl", "sm"],
      lineHeight: [1.3, 2.29],
      letterSpacing: "slacker",
    },
    h6: {
      fontSize: ["lg", "sm"],
      lineHeight: [1.33, 1.71],
      letterSpacing: "slacker",
    },
  },
}
