import React from "react"
import { Box, Flex } from "@chakra-ui/react"

import { withLayout } from "@app/hoc/Layout"
import { Meta } from "@app/components/Meta"
import { Header } from "@app/components/Header/Header"
import { Footer } from "@app/components/Footer/Footer"
import { CartMini } from "@app/components/Cart/CartMini"
import { SearchForm } from "@app/components/Search/Form/SearchForm"
import { Global } from "@app/theme/Global"
import { SubscribePopup } from "@app/components/Subscribe/SubscribePopup"

import type { PageProps } from "@root/types/global"

export const Layout: React.FC<PageProps<any>> = withLayout(({ children, data, isCart, isSearch, location }) => (
  <Flex flexDirection="column" height="100vh">
    <Box flex="1 1 auto">
      <Global />
      <Meta data={data} location={location} />
      <Header location={location} />
      {!isCart && <CartMini />}
      {!isSearch && <SearchForm />}
      <Box as="main">{children}</Box>
      <SubscribePopup location={location} />
    </Box>
    <Box flexShrink={0}>
      <Footer location={location} />
    </Box>
  </Flex>
))
