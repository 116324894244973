import React from "react"

import Logo from "@static/icons/brand/logo.svg"
import FavIcon from "@static/icons/brand/favicon.svg"
import CatBath from "@static/icons/category/bath.svg"
import CatHome from "@static/icons/category/home.svg"
import CatLounge from "@static/icons/category/lounge.svg"
import CatPet from "@static/icons/category/pet.svg"
import CatSleep from "@static/icons/category/sleep.svg"
import SocialEmail from "@static/icons/social/email.svg"
import SocialFacebookBold from "@static/icons/social/facebook-bold.svg"
import SocialFacebook from "@static/icons/social/facebook.svg"
import SocialTiktok from "@static/icons/social/tiktok.svg"
import SocialInstagramBold from "@static/icons/social/instagram-bold.svg"
import SocialInstagram from "@static/icons/social/instagram.svg"
import SocialMessengerBold from "@static/icons/social/messenger-bold.svg"
import SocialMessenger from "@static/icons/social/messenger.svg"
import SocialYoutube from "@static/icons/social/youtube.svg"
import Account from "@static/icons/account.svg"
import ArrowLeft from "@static/icons/arrow-left.svg"
import ArrowNarrowLeft from "@static/icons/arrow-narrow-left.svg"
import ArrowNarrowRight from "@static/icons/arrow-narrow-right.svg"
import ArrowCompactLeft from "@static/icons/arrow-compact-left.svg"
import ArrowCompactRight from "@static/icons/arrow-compact-right.svg"
import ArrowRight from "@static/icons/arrow-right.svg"
import ArrowUpRight from "@static/icons/arrow-up-right.svg"
import Cart from "@static/icons/cart.svg"
import ChevronDown from "@static/icons/chevron-down.svg"
import ChevronLeft from "@static/icons/chevron-left.svg"
import ChevronRight from "@static/icons/chevron-right.svg"
import ChevronUp from "@static/icons/chevron-up.svg"
import ChevronNav from "@static/icons/chevron-nav.svg"
import ChevronNavBack from "@static/icons/chevron-nav-back.svg"
import Close from "@static/icons/close.svg"
import CloseAlt from "@static/icons/close-alt.svg"
import Dash from "@static/icons/dash.svg"
import Delete from "@static/icons/delete.svg"
import Edit from "@static/icons/edit.svg"
import Error from "@static/icons/error.svg"
import Filters from "@static/icons/filters.svg"
import GeoLocation from "@static/icons/geolocation.svg"
import HeartFilled from "@static/icons/heart-filled.svg"
import Heart from "@static/icons/heart.svg"
import Help from "@static/icons/help.svg"
import Hotspot from "@static/icons/hotspot.svg"
import Location from "@static/icons/location.svg"
import Menu from "@static/icons/menu.svg"
import Move from "@static/icons/move.svg"
import MultiColumn from "@static/icons/multi-column.svg"
import Plus from "@static/icons/plus.svg"
import Search from "@static/icons/search.svg"
import SingleColumn from "@static/icons/single-column.svg"
import Zoom from "@static/icons/zoom.svg"
import Truck from "@static/icons/truck.svg"
import Briefcase from "@static/icons/briefcase.svg"
import Hanger from "@static/icons/hanger.svg"

import { Box, BoxProps } from "@chakra-ui/react"

type Props = {
  name: string
  title?: string
} & BoxProps

type Icon = {
  id: string
  url: string
  viewBox: string
}

type Icons = {
  [key: string]: Icon
}

export const Icon: React.FC<Props> = ({ name, title, width = "100%", height = undefined, ...props }) => {
  const icons: Icons = {
    "brand/logo": Logo,
    "brand/favicon": FavIcon,
    "category/bath": CatBath,
    "category/home": CatHome,
    "category/lounge": CatLounge,
    "category/pet": CatPet,
    "category/sleep": CatSleep,
    "social/email": SocialEmail,
    "social/facebook-bold": SocialFacebookBold,
    "social/facebook": SocialFacebook,
    "social/tiktok": SocialTiktok,
    "social/instagram-bold": SocialInstagramBold,
    "social/instagram": SocialInstagram,
    "social/messenger-bold": SocialMessengerBold,
    "social/messenger": SocialMessenger,
    "social/youtube": SocialYoutube,
    account: Account,
    "arrow-left": ArrowLeft,
    "arrow-narrow-left": ArrowNarrowLeft,
    "arrow-narrow-right": ArrowNarrowRight,
    "arrow-compact-left": ArrowCompactLeft,
    "arrow-compact-right": ArrowCompactRight,
    "arrow-right": ArrowRight,
    "arrow-up-right": ArrowUpRight,
    cart: Cart,
    "chevron-down": ChevronDown,
    "chevron-left": ChevronLeft,
    "chevron-right": ChevronRight,
    "chevron-up": ChevronUp,
    "chevron-nav": ChevronNav,
    "chevron-nav-back": ChevronNavBack,
    close: Close,
    closeAlt: CloseAlt,
    dash: Dash,
    delete: Delete,
    edit: Edit,
    error: Error,
    filters: Filters,
    geolocation: GeoLocation,
    "heart-filled": HeartFilled,
    heart: Heart,
    help: Help,
    hotspot: Hotspot,
    location: Location,
    menu: Menu,
    move: Move,
    "multi-column": MultiColumn,
    plus: Plus,
    search: Search,
    "single-column": SingleColumn,
    zoom: Zoom,
    truck: Truck,
    briefcase: Briefcase,
    hanger: Hanger,
  }

  const icon = icons[name]

  return icon ? (
    <Box width={width} height={height} {...props}>
      <svg viewBox={icon.viewBox} height="100%" width="100%" id={icon.id}>
        <use xlinkHref={icon.url} />
      </svg>
    </Box>
  ) : (
    <p>[{title || name}]</p>
  )
}
