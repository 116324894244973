import { useCallback, useEffect, useState } from "react"

import { useCore } from "./useCore"
import { useCustomerContext } from "@app/providers/customer"
import { useCartContext } from "@app/providers/cart"

import type { CustomerProps } from "@root/types/global"
import { NormalisedCart } from "@root/types/cart"
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

type UseEmarsys = {
  emarsysLoaded: boolean
  trackEmarsys: (data: any) => void
  trackPage: (
    customer: CustomerProps | null,
    cart: NormalisedCart | null,
    collection?: GatsbyTypes.SanityCollection,
    product?: GatsbyTypes.SanityProduct
  ) => void
}

export const useEmarsys = (): UseEmarsys => {
  const { cart } = useCartContext()
  const { customer } = useCustomerContext()
  const {
    helpers: { decodeShopifyId, isBrowser, isDev },
  } = useCore()

  const [emarsysLoaded, setEmarsysLoaded] = useState<boolean>(isBrowser ? !!window.ScarabQueue : false)

  useEffect(() => {
    if (emarsysLoaded) return

    // Poll until Emarsys is loaded via GTM
    const interval = setInterval(() => {
      if (isBrowser && window.ScarabQueue) {
        setEmarsysLoaded(true)
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [emarsysLoaded, isBrowser])

  const track = useCallback(
    (id: string, payload: any = null) => {
      if (!emarsysLoaded) return

      const arr = [id]
      if (payload) arr.push(payload)

      if (isBrowser && window.ScarabQueue) {
        if (isDev) console.log(`[Emarsys] ${id} fired`, payload)
        window.ScarabQueue.push(arr)
      } else {
        if (isDev) console.log(`[Emarsys] ${id} ScarabQueue missing`, payload)
      }
    },
    [emarsysLoaded, isBrowser, isDev]
  )

  const trackCustomer = useCallback((customer: CustomerProps) => track("setEmail", customer.email), [track])

  const trackCart = useCallback(
    (lines: CartLine[]) => {
      if (!lines) lines = []
      track(
        "cart",
        lines
          .filter(item => !!item)
          .map(item => ({
            item: decodeShopifyId(item?.merchandise?.id, "ProductVariant"),
            price:
              parseFloat(item?.merchandise?.priceV2?.amount) * item.quantity -
              item.discountAllocations.reduce((sum, cur) => sum + Number(cur.discountedAmount.amount), 0),
            quantity: item.quantity,
          }))
      )
    },
    [decodeShopifyId, track]
  )

  const trackProduct = useCallback((id: string) => track("view", `g/${id}`), [track])

  const trackCollection = useCallback((collection: GatsbyTypes.SanityCollection) => track("category", collection.title), [track])

  const trackGo = useCallback(() => track("go"), [track])

  const trackPage = useCallback(
    (
      customer: CustomerProps | null,
      cart: NormalisedCart | null,
      collection?: GatsbyTypes.SanityCollection,
      product?: GatsbyTypes.SanityProduct
    ) => {
      if (customer) trackCustomer(customer)
      if (cart) trackCart(cart?.lines)
      if (product?.shopify?.id) trackProduct(product.shopify?.id)
      if (collection) trackCollection(collection)
      trackGo()
    },
    [trackCustomer, trackCart, trackCollection, trackGo, trackProduct]
  )

  const trackEmarsys = useCallback((data: any) => trackPage(customer, cart, data?.collection, data?.product), [customer, cart, trackPage])

  return { emarsysLoaded, trackEmarsys, trackPage }
}
