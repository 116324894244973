import React, { forwardRef } from "react"
import { Button, Flex, Text } from "@chakra-ui/react"

import { useNavigationLinkNormaliser } from "@app/hooks/useNavigationHeader"
import { Link } from "@app/components/Link"

import { IndexObject } from "@app/components/Header/Navigation/HeaderNavigationMobile"

export type NavThirdMenu = {
  item: GatsbyTypes.SanityObjectSecondaryMobile | GatsbyTypes.SanityObjectMobileLevel2
  indexes: IndexObject
}

export const NavigationHeaderMobileThirdMenu = forwardRef<HTMLElement, NavThirdMenu>(({ item, indexes }, ref) => {
  const { normaliseLink } = useNavigationLinkNormaliser()
  const { items } = item

  return (
    <Flex
      ref={ref}
      flexDirection="column"
      position="relative"
      overflowX="hidden"
      overflowY="auto"
      height="100%"
      marginBottom="auto"
      paddingBottom="6"
      paddingX="6"
    >
      {items &&
        items?.map((item, index) => {
          const resolvedLink = normaliseLink(item?.link?.[0])

          return resolvedLink ? (
            <Button
              key={index}
              as={Link}
              to={resolvedLink?.url || "/"}
              onClick={() => {
                indexes.onClose()
              }}
              display="flex"
              justifyContent="flex-start"
              variant="ghost"
              _hover={{
                textDecoration: "none",
              }}
              paddingLeft="3"
              borderBottom={index < items.length - 1 ? "1px solid" : ""}
              borderBottomColor="grey.cloud"
            >
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                height={index < items.length - 1 ? "55px" : "56px"}
                flexWrap="wrap"
              >
                <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.navigationText">
                  {resolvedLink.label}
                </Text>
              </Flex>
            </Button>
          ) : null
        })}
    </Flex>
  )
})
