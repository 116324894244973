import React, { memo, useCallback } from "react"
import { Button, Flex, Text } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCartContext } from "@app/providers/cart"
import { useGlobalContext } from "@app/providers/global"
import { Icon } from "@app/components/Icon"

type Props = {
  hover: boolean
  activeDesktop: string | null
}

export const CartWidget: React.FC<Props> = memo(({ hover, activeDesktop }) => {
  const { additionalCart } = useGlobalContext()
  const { count } = useCartContext()
  const { state, dispatch } = useAppContext()

  const handleClick = useCallback(() => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }, [dispatch, state])

  return (
    <Flex
      as={Button}
      position="relative"
      alignItems="center"
      justifyContent="flex-start"
      onClick={handleClick}
      title={`${additionalCart} (${count})`}
      aria-label={`${additionalCart} (${count})`}
      transition="all 0.2s ease"
      variant={
        state.activeInversion && !state.activeMenu && !state.activeNavigation && !hover && !activeDesktop ? "ghostInverted" : "ghost"
      }
      _hover={{
        textDecoration: "underline",
        textUnderlineOffset: "3px",
      }}
    >
      <Icon display={{ base: "block", md: "none" }} color={"currentcolor"} name="cart" width={12} />
      <Text display={{ base: "none", md: "block" }} textTransform="uppercase" letterSpacing="wider" lineHeight={1} mb={-1} size="sm">
        {additionalCart}
      </Text>
      {count !== 0 && (
        <Text
          as="sup"
          fontWeight={500}
          letterSpacing="ample"
          lineHeight={1}
          size="xxxs"
          position="absolute"
          right={{ base: "10px", md: "-5px" }}
          top={{ base: "12px", md: "-5px" }}
        >
          {count === 0 ? `00` : count}
        </Text>
      )}
    </Flex>
  )
})
