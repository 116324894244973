import React, { memo, useCallback } from "react"
import { IconButton } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useGlobalContext } from "@app/providers/global"
import { Icon } from "@app/components/Icon"

type Props = {
  hover: boolean
}

export const HeaderNavigationWidget: React.FC<Props> = memo(({ hover }) => {
  const { additionalMenu } = useGlobalContext()
  const { state, dispatch } = useAppContext()

  const handleClick = useCallback(() => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }, [dispatch, state])

  return (
    <IconButton
      icon={state.activeMenu ? <Icon key="close" name="close" width={12} /> : <Icon key="menu" name="menu" width={12} />}
      onClick={handleClick}
      title={additionalMenu}
      aria-label={additionalMenu || ""}
      variant={state.activeInversion && !state.activeMenu && !state.activeNavigation && !hover ? "ghostInverted" : "ghost"}
    />
  )
})
