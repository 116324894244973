import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useFunctions } from "@app/hooks/useFunctions"
import { useShopify } from "@app/hooks/useShopify"
import { useStorage } from "@app/hooks/useStorage"
import { useActiveTab } from "@app/hooks/useActiveTab"
import { useQueries } from "@app/hooks/useQueries"
import { Cart, CartLine } from "@shopify/hydrogen-react/storefront-api-types"
import { NormalisedCart } from "@root/types/cart"

type ContextProps = {
  id: string
  url: string
  count: number
  cart: NormalisedCart | null
  loading: boolean
  setLoading: (loading: boolean) => void
  countryCode?: string
  currencyCode?: string
  gotoCart: (replace?: boolean) => void
  saveCart: (cart: Cart) => void
  createCart: (presentmentCurrencyCode: string | undefined, forceNew: boolean) => void
  refreshCartStorage: (cart: NormalisedCart) => void
}

export const CartContext = React.createContext<ContextProps | undefined>(undefined)

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    graphql: {
      mutations: { CART_CREATE },
      queries: { GET_CART },
    },
  } = useQueries()
  const {
    store,
    settings: {
      keys,
      cart: { expiryTimeInDays },
    },
  } = useConfigContext()
  const { decorateUrl } = useAnalytics()
  const { callFunction } = useFunctions()
  const { customer } = useCustomerContext()
  const { settings } = useConfigContext()
  const { cartNormaliser } = useShopify()
  const { storage } = useStorage()
  const { refetch: getCartQuery } = useQuery(GET_CART, { fetchPolicy: "no-cache", skip: true })
  const [cartCreate] = useMutation(CART_CREATE)
  const [cart, setCart] = useState<NormalisedCart | null>(null)
  const [loading, setLoading] = useState(false)

  const countryCode = useMemo(
    () => cart?.buyerIdentity?.countryCode || storage.get(keys.market) || store.siteLocation,
    [cart?.buyerIdentity?.countryCode, keys.market, storage, store.siteLocation]
  )

  const currencyCode = useMemo(
    () =>
      cart?.cost?.totalAmount?.currencyCode && cart?.cost?.totalAmount?.currencyCode !== "XXX"
        ? cart?.cost?.totalAmount?.currencyCode
        : store.siteCurrencyCode,
    [cart?.cost?.totalAmount?.currencyCode, store.siteCurrencyCode]
  )

  const id = useMemo(() => cart?.id || storage.get(keys.cart), [cart?.id, keys.cart, storage])

  const url = useMemo(
    () => (cart?.checkoutUrl ? decorateUrl(cart.checkoutUrl.replace(`${store.shopName}.myshopify.com`, store.shopDomain)) : ""),
    [cart, store, decorateUrl]
  )

  const count = useMemo(
    () =>
      cart?.lines?.reduce((count: number, lineItem: CartLine, i: number) => (i ? count + lineItem?.quantity : lineItem?.quantity), 0) || 0,
    [cart]
  )

  useEffect(() => {
    createCart(store.siteLocation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCart = useCallback(async () => {
    try {
      if (id) {
        const countryCode = storage.get(settings.keys.market) || store.siteLocation
        const {
          data: { cart },
        } = await getCartQuery({ countryCode, cartId: id })
        return cart
      }
      return false
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [id, storage, settings.keys.market, store.siteLocation, getCartQuery])

  const saveCart = useCallback(
    (cart: Cart) => {
      try {
        const normalisedCart = cartNormaliser(cart)
        const newCart = { ...normalisedCart, lines: normalisedCart.lines }
        setCart(newCart)
        storage.set(settings.keys.market, newCart.buyerIdentity?.countryCode)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [cartNormaliser, storage, settings.keys.market]
  )

  const refreshCartStorage = useCallback(
    (cart: NormalisedCart) => {
      storage.set(settings.keys.cart, cart.id, expiryTimeInDays || 0)
    },
    [storage, settings.keys.cart, expiryTimeInDays]
  )

  const createCart = useCallback(
    async (countryCode = "AU") => {
      try {
        const existingCart = await getCart()

        if (!existingCart?.id || Object.keys(existingCart).length < 1) {
          const {
            data: {
              cartCreate: { cart },
            },
          } = await cartCreate({
            variables: {
              countryCode,
              input: {
                buyerIdentity: {
                  countryCode,
                },
              },
            },
          })
          if (cart) {
            saveCart(cart)
            refreshCartStorage(cart)
          }
        } else {
          saveCart(existingCart)
        }
      } catch (e) {
        console.error((e as Error).message)
        const isThrottled = (e as Error).message?.toLowerCase()?.includes("throttled")
        if (!isThrottled) storage.remove(settings.keys.cart)
      }
    },
    [getCart, cartCreate, saveCart, refreshCartStorage, storage, settings.keys.cart]
  )

  const gotoCart = useCallback(
    async (replace?: boolean) => {
      if (!cart || !cart?.checkoutUrl) return
      setLoading(true)

      if (customer?.email) {
        try {
          const response = await callFunction("checkout-multipass", {
            customerEmail: customer.email,
            checkoutId: cart.id,
            webUrl: cart.checkoutUrl,
          })
          const url = response.status !== "error" && response.body.includes("https://") ? response.body : cart.checkoutUrl

          setLoading(false)
          if (replace) {
            window.location.replace(url)
          } else {
            window.location.href = url
          }
        } catch (error) {
          setLoading(false)
          if (replace) {
            window.location.replace(cart.checkoutUrl)
          } else {
            window.location.href = cart.checkoutUrl
          }
        }
      } else {
        setLoading(false)
        if (replace) {
          window.location.replace(cart.checkoutUrl)
        } else {
          window.location.href = cart.checkoutUrl
        }
      }
    },
    [callFunction, cart, customer]
  )

  const { visibilityState } = useActiveTab()

  useEffect(() => {
    if (cart) createCart(store.siteLocation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilityState])

  const contextValue = useMemo<ContextProps>(
    () => ({
      id,
      url,
      count,
      loading,
      setLoading,
      cart,
      gotoCart,
      saveCart,
      createCart,
      refreshCartStorage,
      countryCode,
      currencyCode,
    }),
    [id, url, count, loading, setLoading, cart, gotoCart, saveCart, createCart, refreshCartStorage, countryCode, currencyCode]
  )

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export const useCartContext = (): ContextProps => ({ ...useContext(CartContext) } as ContextProps)
