export const styles = {
  global: {
    body: {
      bg: "white",
      color: "grey.darker",
      width: "100%",
    },
    html: {
      width: "100%",
    },
    a: {
      transition: "color 0.2s ease",
    },
    "div.chakra-text, .rte": {
      "& > *:not(:last-child)": {
        mb: 4,
      },
      "p + ul": {
        mt: -3.5,
      },
      ul: {
        listStylePosition: "inside",
      },
    },
    ".firebase-emulator-warning": {
      d: "none",
    },
    "input::placeholder": {
      color: "inherit",
    },
    ".chakra-button, .chakra-tabs__tab, a": {
      ".chakra-button__icon": {
        h: 4,
        mb: 0.5,
        w: 4,
      },
      "&.ghost, &.ghostInverted": {
        ".chakra-button__icon": {
          mb: 1,
          transition: "transform 0.2s ease",
        },
        _hover: {
          ".chakra-button__icon": {
            transform: "rotate(45deg)",
          },
        },
      },
      "&.primary": {
        "span, .add-icon": {
          transition: "all 0.2s ease",
        },
        "& > div:not(.chakra-button__spinner, .add-icon)": {
          d: "none",
        },
        ".add-icon": {
          pos: "absolute",
          ml: 0,
          w: 8,
          transform: "translateY(100%)",
          visibility: "hidden",
          opacity: 0,
        },
        _hover: {
          span: {
            transform: "translateY(-100%)",
            visibility: "hidden",
            opacity: 0,
          },
          ".add-icon": {
            transform: "translateY(0)",
            visibility: "visible",
            opacity: 1,
          },
        },
        ".chakra-button__spinner": {
          ".add-icon": {
            pos: "static",
            transform: "translateY(0)",
            visibility: "visible",
            opacity: 1,
          },
        },
      },
      "&.underline": {
        d: "inline-block",
        _after: {
          content: `" "`,
          d: "block",
          borderBottom: "1px solid",
          borderBottomColor: "brand.sunshine",
          transition: "width 0.4s ease",
          w: 0,
        },
        _hover: {
          _after: {
            w: "full",
          },
        },
        _active: {
          _after: {
            w: "full",
          },
        },
        _focus: {
          _after: {
            w: "full",
          },
        },
      },
      "&.underlineTabs": {
        d: "inline-block",
        _after: {
          content: `" "`,
          d: "block",
          borderBottom: "1px solid",
          borderBottomColor: "brand.sunshine",
          transition: "width 0.4s ease",
          w: 0,
        },
        _hover: {
          _after: {
            w: "full",
          },
        },
      },
      "&.underlineInverted": {
        d: "inline-block",
        _after: {
          content: `" "`,
          d: "block",
          borderBottom: "1px solid",
          borderBottomColor: "white",
          transition: "width 0.4s ease",
          w: 0,
        },
        _hover: {
          _after: {
            w: "full",
          },
        },
        _active: {
          _after: {
            w: "full",
          },
        },
        _focus: {
          _after: {
            w: "full",
          },
        },
      },
    },
    ".swiper-pagination-bullets": {
      d: "flex",
      alignItems: "center",
      justifyContent: "center",
      pb: 6.25,
      zIndex: 1,
    },
    ".swiper-pagination-bullets > .swiper-pagination-bullet": {
      d: "block",
      w: 1.5,
      h: 1.5,
      mx: 1.25,
      borderRadius: "full",
      cursor: "pointer",
      opacity: 0.5,
      bg: "grey.cloud",
      _hover: {
        bg: "white",
      },
    },
    ".swiper-pagination-bullets > .swiper-pagination-bullet.swiper-pagination-bullet-active": {
      bg: "grey.cloud",
      opacity: 1,
      _hover: {
        bg: "white",
      },
    },
    ".swiper-disabled": {
      opacity: "0.5",
    },
    ".swiper-hero .swiper-pagination-bullets": {
      justifyContent: "flex-end",
      pr: 4,
    },
    ".swiper-hero .swiper-pagination-bullets > .swiper-pagination-bullet": {
      w: 3,
      h: 3,
      mx: 3,
      border: "solid 1px currentColor",
      bg: "transparent",
      _hover: {
        bg: "currentColor",
      },
    },

    ".swiper-hero .swiper-pagination-bullets > .swiper-pagination-bullet.swiper-pagination-bullet-active": {
      bg: "currentColor",
      _hover: {
        bg: "currentColor",
      },
    },
  },
}
