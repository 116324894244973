import React, { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Text } from "@chakra-ui/react"

import { FooterSubscribeForm } from "./FooterSubscribeForm"
import { useNewsletter } from "@app/hooks/useNewsletter"
import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

export const FooterSubscribe: React.FC<Props> = memo(({ location }) => {
  const { newsletter } = useStaticQuery<GatsbyTypes.StaticFooterSubscribeQuery>(graphql`
    query StaticFooterSubscribe {
      newsletter: sanitySettingNewsletter {
        additionalTitle
        additionalSubtitle
      }
    }
  `)
  const { data, errors, handleSubmit, handleChange, loading, success } = useNewsletter(location)

  return (
    <Box order={{ base: 1, md: "unset" }} justifySelf="end" maxW={["none", "md"]} ml="auto">
      <Text color="grey.navigationText" lineHeight={2.5} mb={3} size="sm" textTransform="capitalize" fontWeight="bold">
        {newsletter?.additionalTitle}
      </Text>
      {!success && (
        <Text color="grey.navigationText" lineHeight={1.2} mb={6} size="sm">
          {newsletter?.additionalSubtitle}
        </Text>
      )}
      <FooterSubscribeForm
        location={location}
        data={data}
        errors={errors}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        loading={loading}
        success={success}
      />
    </Box>
  )
})
