import React from "react"
import { Box, Flex, FlexProps, Text } from "@chakra-ui/react"

import { Icon } from "@app/components/Icon"

import type { DisplayableErrorProps } from "@root/types/global"

export type Props = FlexProps & {
  errors?: Array<DisplayableErrorProps | string>
  errorIcon?: boolean
}

export const FormErrors: React.FC<Props> = ({ errors = [], errorIcon = true, ...props }): JSX.Element | null =>
  errors?.length > 0 ? (
    <>
      {errors?.map(error => (
        <Flex key={error?.code || error?.toString()} alignItems="center" color="brand.error" justifyContent="flex-start" {...props}>
          {errorIcon && (
            <Box flexShrink={0}>
              <Icon name="error" width={3} />
            </Box>
          )}
          <Text letterSpacing="generous" size={errorIcon ? "xxxs" : "xs"} mb={-0.75} ml={errorIcon ? 2 : 0}>
            {error?.message || error?.toString()}
          </Text>
        </Flex>
      ))}
    </>
  ) : null
