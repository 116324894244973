import React, { memo, useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Divider, Flex, HStack, Text, VStack } from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"
import { useShopify } from "@app/hooks/useShopify"
import { FormButton } from "@app/components/Form/FormButton"
import { CartPayments } from "./CartPayments"

type Props = {
  isCartPage?: boolean
  disableCart?: boolean
}

export const CartSummary: React.FC<Props> = memo(({ isCartPage = false, disableCart = false }) => {
  const { cart } = useStaticQuery<GatsbyTypes.StaticCartSummaryQuery>(graphql`
    query StaticCartSummary {
      cart: sanityPageCart {
        additionalShipping
        additionalShippingRate
        additionalSubtotal
        additionalTotal
        additionalCheckout
      }
    }
  `)
  const { cart: cartData, gotoCart, loading } = useCartContext()
  const { formatMoney } = useShopify()
  const handleCart = useCallback(() => gotoCart(), [gotoCart])

  const subtotal = useMemo(
    () => formatMoney(Number(cartData?.cost?.subtotalAmount?.amount)),
    [cartData?.cost?.subtotalAmount?.amount, formatMoney]
  )
  const total = useMemo(
    () => formatMoney(Number(cartData?.cost?.subtotalAmount?.amount)),
    [cartData?.cost?.subtotalAmount?.amount, formatMoney]
  )

  return isCartPage ? (
    <Flex direction="column" alignItems="stretch" justifyContent="flex-start" w="full" maxW={["unset", 334]}>
      <VStack direction="column" alignItems="stretch" justifyContent="flex-start" w="full" spacing={6} mb={12}>
        <VStack alignItems="stretch" justifyContent="flex-start" spacing={4} w="full">
          <HStack justify="space-between" mb={-1}>
            <Text letterSpacing="generous" size="xs">
              {cart?.additionalSubtotal}
            </Text>
            <Text letterSpacing="generous" size="xs">
              {subtotal}
            </Text>
          </HStack>
          <HStack justify="space-between" mb={-1}>
            <Text letterSpacing="generous" size="xs">
              {cart?.additionalShipping}
            </Text>
            <Text letterSpacing="generous" size="xs">
              {cart?.additionalShippingRate}
            </Text>
          </HStack>
        </VStack>
        <Divider borderBottomColor="grey.fog" />
        <HStack justify="space-between" mb={-1}>
          <Text letterSpacing="generous" color="grey.coal" size="sm" fontWeight={700}>
            {cart?.additionalTotal}
          </Text>
          <Text letterSpacing="generous" color="grey.coal" size="sm" fontWeight={700}>
            {total}
          </Text>
        </HStack>
        <Divider borderBottomColor="grey.fog" />
      </VStack>
      <VStack direction="column" alignItems="stretch" justifyContent="flex-start" w="full" spacing={6}>
        <FormButton
          isLoading={loading}
          onClick={handleCart}
          size="md"
          title={cart?.additionalCheckout}
          aria-label={cart?.additionalCheckout}
          variant="primary"
          w="full"
        >
          {cart?.additionalCheckout}
        </FormButton>
        <CartPayments />
      </VStack>
    </Flex>
  ) : (
    <VStack alignItems="stretch" justifyContent="flex-start" spacing={isCartPage ? 6 : 4} w="full" maxW={isCartPage ? 334 : "unset"}>
      <HStack justify="space-between" mb={-1}>
        <Text letterSpacing="generous" size="sm">
          {cart?.additionalSubtotal}
        </Text>
        <Text letterSpacing="generous" size="sm">{`— ${total}`}</Text>
      </HStack>
      <FormButton
        isLoading={loading}
        isDisabled={disableCart}
        onClick={handleCart}
        size="md"
        title={cart?.additionalCheckout}
        aria-label={cart?.additionalCheckout}
        variant="primary"
        w="full"
      >
        {cart?.additionalCheckout}
      </FormButton>
      <CartPayments />
    </VStack>
  )
})
